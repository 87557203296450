import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { PaletteMode, ThemeProvider } from "@mui/material";
import { darkTheme } from "./theme";

import { MainScreen } from './screen/MainScreen';

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <MainScreen />
    </ThemeProvider>
    
    // <Router>
    //   <Routes>
    //     <Route path='/' element={<MainScreen />}/>
    //   </Routes>
    // </Router>
  );
}

export default App;
