import { FormControl, InputAdornment, OutlinedInput } from '@mui/material'
import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

interface State {
    email: string;

}

// Switch Email vs Telegram 
export const EmailInput = () => {
    const [values, setValues] = React.useState<State>({
        email: '',
      });
    
      const handleChange =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues({ ...values, [prop]: event.target.value });
        };
  return (
    <FormControl sx={{ padding: '2rem 0.5rem 1rem 0.5rem', width: '100%',  }} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            value={values.email}
            onChange={handleChange('email')}
            startAdornment={
                <InputAdornment position="start">
                    <MailOutlineIcon color='secondary' fontSize='large'/>
                </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            color='secondary'
            sx={{
                borderRadius: '4rem',
                fontSize: '1.5rem',
                color: 'secondary.main',
                
            }}
            
            placeholder="Email"
          />
        </FormControl>
  )
}
