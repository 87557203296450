import React from 'react';
// import styled from '@emotion/styled';
import { styled } from '@mui/system';
import ImgScreenCrm01 from '../../assets/jpg/Screenshot-02.jpg';
import { BtnLargeCTA } from '../../components/Buttons/BtnLargeCTA';
import { useTheme } from '@mui/system';
import { ThemeProvider } from '@emotion/react';
import { EmailInput } from '../../components/Inputs/EmailInput';

export const MainScreen = () => {
    const theme = useTheme();
  return (
//     <DivContainer>
//     <p className={s.logoTitle}>FansControl</p>
//     <h1 className={s.bigTitle}>
//         <span className={s.colorBlue}>Simple and fast CRM </span>
//         <span className={s.colorPink}> for your OnlyFans agency. </span>
//         <br/> No more Google Sheets and monkey job.
//     </h1>
// <div className={s.gridContainer}>
//     {/* <p>Teset</p> */}
//     <img className={s.imgScreen} src={ImgScreenCrm01} alt='no-alt'/>

//     <BtnLargeCTA />
// </div>
// </DivContainer>
    <ThemeProvider theme={theme}>
        <DivContainer>
                <LogoTitle>FansControl</LogoTitle>
                <BigTitle>
                    <SpanColorPrimary>Simple and fast CRM </SpanColorPrimary>
                    <SpanColorSecondary> for your OnlyFans agency. </SpanColorSecondary>
                    <br/> No more Google Sheets and monkey job.
                </BigTitle>
            <GridContainer>
                <ImgContainer src={ImgScreenCrm01} alt='no-alt'/>
                <FormContainer>
                    <TitleH2>
                    It's time to really control 
                        <SpanColorSecondary>
                        <br/>your fans and chatters
                        </SpanColorSecondary>
                    </TitleH2>
                    <EmailInput />
                    <BtnLargeCTA />
                </FormContainer>
                
            </GridContainer>
        </DivContainer>
    </ThemeProvider>
  )
}

const DivContainer = styled('div')({
    maxWidth: '120rem',
    margin: '2rem auto',
    height: 100,

    '@media(max-width:800px)': {
        margin:'1rem 2rem',
    }
})

const FormContainer = styled('div')({
    width: '24rem',
    height: '100%',
    margin: '4rem auto 0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '@media(max-width:800px)': {
        width: '80%',
    }
})

const GridContainer = styled('div')({
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    width: '100%',
    alignItems: 'flex-end',

    '@media(max-width:800px)': {
        gridTemplateColumns: '1fr',
    }
})

const LogoTitle = styled('p')(({ theme }) => ({
    margin: '5rem 0 0rem 0',
    color:  theme.palette.secondary.main, 
    fontSize: '2.4rem',
    fontWeight: 200,
    textAlign: 'center',
}))

const BigTitle = styled('p')(({ theme }) => ({
    margin: '1rem 0 4rem 0',
    fontSize: '3rem',
    textAlign: 'center',
    color: theme.palette.text.primary,
}))
const TitleH2 = styled('p')(({ theme }) => ({
    fontSize: '2.2rem',
    textAlign: 'center',
    color: theme.palette.text.primary,
}))
const SpanColorPrimary = styled('span')(({ theme }) => ({
    color:  theme.palette.primary.main, 
}))
const SpanColorSecondary = styled('span')(({ theme }) => ({
    color:  theme.palette.secondary.main, 
}))

const ImgContainer = styled('img')({
    width: '100%',
    border: '1px solid #01c6ee2c',
})
