import { createTheme, Theme, ThemeOptions } from "@mui/material";

export type MsfpTheme = Theme & {
  appBar: {
    main: string;
  };
};

export type MsfpThemeOptions = ThemeOptions & {
  appBar?: {
    main?: string;
  };
};

export const customOverridesLight: MsfpThemeOptions = {
  appBar: {
    main: "#272727",
  },
  palette: {
    mode: "light",
    primary: {
      main: "#FF4D00",
    },
    secondary: {
      main: "#2D2D2D",
      contrastText: "#fff",
    },
    background: {
      default: "#F5F5F5",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#757575",
      secondary: '#9D9D9D',
    },
    action: {
      selected: "#FF4D00",
    },
  },
  typography: {
    fontFamily: ["Roboto","Helvetica","Arial",'sans-serif'].join(',')
  },
  components: { MuiListItemButton: 
    { styleOverrides:  
      { root: 
        { "&.Mui-selected": { backgroundColor: "rgba(255, 77, 0, 0.1)" ,
          "& .MuiListItemIcon-root": { color: "#FF4D00" } ,
          "& .MuiListItemText-root": { color: "#2D2D2D" } ,
        },
        },
      },
    } 
  }
};

export const customOverridesDark: MsfpThemeOptions = {
  appBar: {
    main: "#272727",
  },
  palette: {
    mode: "dark",
    primary: {
      // main: "#B88502",
      // main: '#FE1355',
      // main: '#14FFF2',
      // main: '#E41376',
      light: '#4B637B',
      main: '#01C8EE',
      dark: '#051739', 
    },
    secondary: {
      main: "#E41376",
    },
    text: {
      primary: "#F5F5F5",
    },
    background: {
      default: "#011028",
      paper: "#001021",
    },
    error: {
      main: '#f44336',
      dark: '#d32f2f',
    }
  },
  typography: {
    fontFamily: ["Roboto","Helvetica","Arial",'sans-serif'].join(',')
  },
  components: { MuiListItemButton: 
    { styleOverrides:  
      { root: 
        { "&.Mui-selected": { 
          "& .MuiListItemIcon-root": { color: "#FF4D00" }
        },
        },
      },
    } 
  }
};

export const lightTheme = createTheme(customOverridesLight) as MsfpTheme;
export const darkTheme = createTheme(customOverridesDark) as MsfpTheme;
