import * as React from 'react';
import Button from '@mui/material/Button';

export const BtnLargeCTA = () => {
  return (
      <Button 
        variant="contained" 
        color='secondary'
        size="large" 
        // endIcon={<SendIcon />} 
        sx={{
            height: '5.5rem',
            borderRadius: '4rem',
            color: 'primary.dark',
            fontSize: '1.4rem',
            fontWeight: 900,
            width: '100%',
        }}
    >
        Get Started - It's Free
    </Button>
  );
}